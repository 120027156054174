import { FormInstance } from "antd/es/form";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IProject_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IProject_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  SaveRecord_1: number;
  SaveRecord_1_dummy: number;
  SaveRecord_2: number;
  SaveRecord_2_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  GetKUserID: any[];
  GetKUserID_dummy: any[];
  GetProject: any[];
  GetProject_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  IsAutoEpEnabled: boolean;
  IsAutoEpEnabled_dummy: boolean;
  SetValueOf: any;
  SetValueOf_dummy: any;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  CheckAutoTotal: any[];
  CheckAutoTotal_dummy: any[];
  IsAutoCfpEnabled: boolean;
  IsAutoCfpEnabled_dummy: boolean;
  Notify: boolean;
  Notify_dummy: boolean;
  isComp214015Visible: "visible" | "hidden";
  isComp489202Visible: "visible" | "hidden";
  isComp342574Visible: "visible" | "hidden";
  isComp54786Enabled: "enabled" | "disabled";
  isComp624078Enabled: "enabled" | "disabled";
  isComp913889Enabled: "enabled" | "disabled";
  isComp578505Enabled: "enabled" | "disabled";
  isComp890482Enabled: "enabled" | "disabled";
  isComp481472Enabled: "enabled" | "disabled";
}

export class Project_Form_ScreenBase extends React.PureComponent<IProject_Form_ScreenProps, any> {
  project_form_958748_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "fa03982a-4767-4ecc-913e-fe2ad550c8a7",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 306657, PropertyName: "label", Value: "Kaydet" },
        { Id: 636116, PropertyName: "label", Value: "İptal" },
        { Id: 214015, PropertyName: "value", Value: "Proje Güncelleme" },
        { Id: 489202, PropertyName: "value", Value: "Metrik Konfigürasyon" },
        { Id: 342574, PropertyName: "value", Value: "Proje Ekleme" },
        { Id: 989536, PropertyName: "value", Value: "Proje Kodu" },
        { Id: 571059, PropertyName: "value", Value: "Proje Adı" },
        { Id: 103269, PropertyName: "value", Value: "Proje Dili" },
        { Id: 270139, PropertyName: "value", Value: "Event" },
        { Id: 998964, PropertyName: "value", Value: "interaction" },
        { Id: 130721, PropertyName: "value", Value: "Communication" },
        { Id: 557991, PropertyName: "value", Value: "Process" },
        { Id: 666553, PropertyName: "value", Value: "Toplam EP" },
        { Id: 578505, PropertyName: "value", Value: "Toplam EP Otomatik Hesaplansın" },
        { Id: 821478, PropertyName: "value", Value: "Cosmic" },
        { Id: 782871, PropertyName: "value", Value: "Entry" },
        { Id: 269127, PropertyName: "value", Value: "Read" },
        { Id: 331974, PropertyName: "value", Value: "Write" },
        { Id: 740128, PropertyName: "value", Value: "Exit" },
        { Id: 68155, PropertyName: "value", Value: "Toplam CFP" },
        { Id: 481472, PropertyName: "value", Value: "Toplam CFP Otomatik Hesaplansın" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.project_form_54786_value_kuikaTextInputRef = React.createRef();
    this.project_form_624078_value_kuikaTextInputRef = React.createRef();
    this.project_form_958748_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      SaveRecord_1: false,
      SaveRecord_2: false,
      GoBack: "",
      GetKUserID: [],
      GetProject: [],
      ChangeEnabledOf: "",
      IsAutoEpEnabled: false,
      IfThenElse: false,
      CheckAutoTotal: [],
      IsAutoCfpEnabled: false,
      Notify: false,
      dataSource_958748: [
        { key: "TR", text: "TR" },
        { key: "EN", text: "EN" }
      ],
      isComp214015Visible: "hidden",
      isComp489202Visible: "hidden",
      isComp342574Visible: "hidden",
      isComp54786Enabled: "enabled",
      isComp624078Enabled: "enabled",
      isComp913889Enabled: "disabled",
      isComp578505Enabled: "disabled",
      isComp890482Enabled: "disabled",
      isComp481472Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("project_form", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Project_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("project_form", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("project_form", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Project_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      project_form_54786_value: this.state.GetProject?.at?.(0)?.projectCode ?? undefined,
      project_form_624078_value: this.state.GetProject?.at?.(0)?.projectName ?? undefined,
      project_form_958748_value: this.state.GetProject?.at?.(0)?.language ?? undefined,
      project_form_245602_value: this.state.GetProject?.at?.(0)?.interaction ?? undefined,
      project_form_708552_value: this.state.GetProject?.at?.(0)?.communication ?? undefined,
      project_form_127646_value: this.state.GetProject?.at?.(0)?.eventProcess ?? undefined,
      project_form_863986_value: this.state.GetProject?.at?.(0)?.totalEP ?? undefined,
      project_form_913889_value: this.state.GetProject?.at?.(0)?.autoTotalEP ?? undefined,
      project_form_856823_value: this.state.GetProject?.at?.(0)?.entry ?? undefined,
      project_form_446236_value: this.state.GetProject?.at?.(0)?.cosmicRead ?? undefined,
      project_form_679485_value: this.state.GetProject?.at?.(0)?.cosmicWrite ?? undefined,
      project_form_646747_value: this.state.GetProject?.at?.(0)?.cosmicExit ?? undefined,
      project_form_322751_value: this.state.GetProject?.at?.(0)?.totalCFP ?? undefined,
      project_form_890482_value: this.state.GetProject?.at?.(0)?.autoTotalCFP ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  Project_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUserID = result?.data.getKUserID;
    stateVars.GetProject = result?.data.getProject;
    formVars.project_form_54786_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectCode
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectCode
        : null
    );
    formVars.project_form_624078_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectName
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectName
        : null
    );
    formVars.project_form_958748_value =
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].language
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].language
        : null;
    formVars.project_form_245602_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].interaction
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].interaction
        : null
    );
    formVars.project_form_708552_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].communication
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].communication
        : null
    );
    formVars.project_form_127646_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].eventProcess
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].eventProcess
        : null
    );
    formVars.project_form_863986_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].totalEP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].totalEP
        : null
    );
    formVars.project_form_913889_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].autoTotalEP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].autoTotalEP
        : null
    );
    formVars.project_form_856823_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].entry
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].entry
        : null
    );
    formVars.project_form_446236_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].cosmicRead
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].cosmicRead
        : null
    );
    formVars.project_form_679485_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].cosmicWrite
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].cosmicWrite
        : null
    );
    formVars.project_form_646747_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].cosmicExit
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].cosmicExit
        : null
    );
    formVars.project_form_322751_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].totalCFP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].totalCFP
        : null
    );
    formVars.project_form_890482_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].autoTotalCFP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].autoTotalCFP
        : null
    );
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.ScreenType ?? this.props.screenInputs.screentype,
        "Create"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp54786Enabled", "string"),
        ReactSystemFunctions.convertToTypeByName("disabled", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormPageInit2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Project_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        this.props.screenInputs.ScreenType ?? this.props.screenInputs.screentype,
        "Create"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(
        this,
        ReactSystemFunctions.convertToTypeByName("isComp624078Enabled", "string"),
        ReactSystemFunctions.convertToTypeByName("disabled", "string")
      );

      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormPageInit3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormPageInit3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  Project_FormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp214015Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.ScreenType ?? this.props.screenInputs.screentype,
        "Edit"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp489202Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.ScreenType ?? this.props.screenInputs.screentype,
        "Metric"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp342574Visible =
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.ScreenType ?? this.props.screenInputs.screentype,
        "Create"
      ) === true
        ? "visible"
        : "hidden";
    formVars.project_form_54786_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectCode
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectCode
        : null
    );

    formVars.project_form_624078_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].projectName
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].projectName
        : null
    );

    formVars.project_form_958748_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].language
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].language
        : null
    );

    formVars.project_form_245602_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].interaction
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].interaction
        : null
    );

    formVars.project_form_708552_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].communication
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].communication
        : null
    );

    formVars.project_form_127646_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].eventProcess
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].eventProcess
        : null
    );

    formVars.project_form_863986_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].totalEP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].totalEP
        : null
    );

    formVars.project_form_913889_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].autoTotalEP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].autoTotalEP
        : null
    );

    stateVars.isComp913889Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp578505Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    formVars.project_form_856823_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].entry
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].entry
        : null
    );

    formVars.project_form_446236_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].cosmicRead
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].cosmicRead
        : null
    );

    formVars.project_form_679485_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].cosmicWrite
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].cosmicWrite
        : null
    );

    formVars.project_form_646747_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].cosmicExit
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].cosmicExit
        : null
    );

    formVars.project_form_322751_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].totalCFP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].totalCFP
        : null
    );

    formVars.project_form_890482_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProject?.length > 0
        ? stateVars.GetProject[0].autoTotalCFP
        : this.state.GetProject?.length > 0
        ? this.state.GetProject[0].autoTotalCFP
        : null
    );

    stateVars.isComp890482Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp481472Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Project_FormComponent_306657_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_624078_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "project_form_624078_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_54786_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "project_form_54786_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_958748_value", "value", "", "key", "")
        ),
        null
      ),
      message: "Proje dili seçimi gerekli.",
      formName: "project_form_958748_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      ProjectName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_624078_value", "value", "", "", "")
        ),
        "string"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProjectCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_54786_value", "value", "", "", "")
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CompanyID ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUserID?.length > 0
            ? stateVars.GetKUserID[0].id
            : this.state.GetKUserID?.length > 0
            ? this.state.GetKUserID[0].id
            : null
        ),
        "Guid"
      ),
      CreateDatetime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      AutoTotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_890482_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AutoTotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_913889_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_958748_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].id
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Project_Form/Project_FormComponent_306657_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord_1 = result?.data.saveRecord_1;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormComponent_306657_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormComponent_306657_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_306657_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_624078_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "project_form_624078_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_54786_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "project_form_54786_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_958748_value", "value", "", "key", "")
        ),
        null
      ),
      message: "Proje dili seçimi gerekli.",
      formName: "project_form_958748_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      ProjectName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_624078_value", "value", "", "", "")
        ),
        "string"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProjectCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_54786_value", "value", "", "", "")
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.CompanyID ?? this.props.screenInputs.companyid,
        "Guid"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUserID?.length > 0
            ? stateVars.GetKUserID[0].id
            : this.state.GetKUserID?.length > 0
            ? this.state.GetKUserID[0].id
            : null
        ),
        "Guid"
      ),
      AutoTotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_913889_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AutoTotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_890482_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Language_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "project_form_958748_value", "value", "", "key", "")
        ),
        "string"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0
            ? stateVars.GetProject[0].id
            : this.state.GetProject?.length > 0
            ? this.state.GetProject[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Project_Form/Project_FormComponent_306657_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord_2 = result?.data.saveRecord_2;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormComponent_306657_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.Project_FormComponent_306657_onClick2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_306657_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_636116_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_846558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_245602_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      process_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_245602_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoEpEnabled = result?.data.isAutoEpEnabled;
    stateVars.isComp913889Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp578505Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_245602_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_245602_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_913889_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_245602_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoEpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_245602_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_708552_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      process_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_708552_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoEpEnabled = result?.data.isAutoEpEnabled;
    stateVars.isComp913889Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp578505Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_708552_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_708552_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_913889_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_708552_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoEpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_708552_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_127646_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      process_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_127646_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoEpEnabled = result?.data.isAutoEpEnabled;
    stateVars.isComp913889Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp578505Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_127646_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_127646_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_913889_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_127646_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoEpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_127646_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_863986_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      process_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalEp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_863986_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoEpEnabled = result?.data.isAutoEpEnabled;
    stateVars.isComp913889Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp578505Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoEpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_863986_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_863986_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_913889_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_863986_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoEpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_863986_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_913889_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AutoEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_913889_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AutoCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_890482_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_913889_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckAutoTotal = result?.data.checkAutoTotal;
    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  Project_FormComponent_856823_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      read_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      write_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      exit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_856823_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoCfpEnabled = result?.data.isAutoCfpEnabled;
    stateVars.isComp890482Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp481472Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_856823_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_856823_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_890482_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_856823_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_856823_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_446236_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      read_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      write_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      exit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_446236_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoCfpEnabled = result?.data.isAutoCfpEnabled;
    stateVars.isComp890482Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp481472Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_446236_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_446236_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_890482_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_446236_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_446236_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_679485_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      read_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      write_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      exit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_679485_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoCfpEnabled = result?.data.isAutoCfpEnabled;
    stateVars.isComp890482Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp481472Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_679485_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_679485_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_890482_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_679485_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_679485_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_646747_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      read_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      write_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      exit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_646747_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoCfpEnabled = result?.data.isAutoCfpEnabled;
    stateVars.isComp890482Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp481472Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_646747_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_646747_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_890482_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_646747_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_646747_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_322751_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      ),
      read_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      write_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      exit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      totalCfp_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_322751_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsAutoCfpEnabled = result?.data.isAutoCfpEnabled;
    stateVars.isComp890482Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp481472Enabled =
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, stateVars.IsAutoCfpEnabled), true) === true
        ? "enabled"
        : "disabled";
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_322751_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_322751_onChange_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      ReactSystemFunctions.convertToTypeByName("project_form_890482_value", "string"),
      ReactSystemFunctions.convertToTypeByName("False", "boolean"),
      null
    );

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  Project_FormComponent_322751_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.value(this, this.state.IsAutoCfpEnabled), false)) {
      isErrorOccurred = await this.Project_FormComponent_322751_onChange_if1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Project_FormComponent_890482_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      AutoEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_913889_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_708552_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_245602_value", "value", "", "", "")
        ),
        "boolean"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_127646_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_863986_value", "value", "", "", "")
        ),
        "boolean"
      ),
      AutoCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_890482_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_646747_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_679485_value", "value", "", "", "")
        ),
        "boolean"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_446236_value", "value", "", "", "")
        ),
        "boolean"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_322751_value", "value", "", "", "")
        ),
        "boolean"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "project_form_856823_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Project_Form/Project_FormComponent_890482_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CheckAutoTotal = result?.data.checkAutoTotal;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.Project_FormComponent_890482_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Project_FormComponent_890482_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.CheckAutoTotal?.length > 0
            ? stateVars.CheckAutoTotal[0].result
            : this.state.CheckAutoTotal?.length > 0
            ? this.state.CheckAutoTotal[0].result
            : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "0468b38e_8acc_a7af_b4af_242a67d43eb7_notify",
          this.defaultML,
          "Tüm metrikler seçilmeli"
        ),
        ReactSystemFunctions.convertToTypeByName("danger", "string"),
        ReactSystemFunctions.convertToTypeByName("bottom-right", "string"),
        ReactSystemFunctions.convertToTypeByName("0", "number"),
        null,
        null,
        null
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
