import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUserForm_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUserForm_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  CheckIfKUserAlreadyExistsByUserName: number;
  CheckIfKUserAlreadyExistsByUserName_dummy: number;
  InvertBit: any[];
  InvertBit_dummy: any[];
  SaveUser: any;
  SaveUser_dummy: any;
  SaveRecord_1: number;
  SaveRecord_1_dummy: number;
  SaveRecord_2: number;
  SaveRecord_2_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  GetKUser: any[];
  GetKUser_dummy: any[];
  GetCompanyByUserAccess: any[];
  GetCompanyByUserAccess_dummy: any[];
  GetRolesByUserAccess: any[];
  GetRolesByUserAccess_dummy: any[];
  IsUserActive: any[];
  IsUserActive_dummy: any[];
  isComp548941Visible: "visible" | "hidden";
}

export class UserForm_ScreenBase extends React.PureComponent<IUserForm_ScreenProps, any> {
  userform_126259_value_kuikaSelectBoxRef: React.RefObject<any>;
  userform_204888_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "fde3cb79-b55d-4ca7-81f6-edfb953a6e2d",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 839541, PropertyName: "label", Value: "Kaydet" },
        { Id: 25335, PropertyName: "label", Value: "İptal" },
        { Id: 432538, PropertyName: "value", Value: "Kullanıcı Detay" },
        { Id: 885244, PropertyName: "value", Value: "E-Posta" },
        { Id: 253741, PropertyName: "value", Value: "Ad" },
        { Id: 763885, PropertyName: "value", Value: "Soyad" },
        { Id: 881558, PropertyName: "value", Value: "Şirket" },
        { Id: 126259, PropertyName: "placeholder", Value: "Şirket Seçiniz..." },
        { Id: 389639, PropertyName: "value", Value: "Rol" },
        { Id: 204888, PropertyName: "placeholder", Value: "Rol Seçiniz..." },
        { Id: 117739, PropertyName: "value", Value: "Aktif" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.userform_6098384_value_kuikaTextInputRef = React.createRef();
    this.userform_197494_value_kuikaTextInputRef = React.createRef();
    this.userform_516795_value_kuikaTextInputRef = React.createRef();
    this.userform_126259_value_kuikaSelectBoxRef = React.createRef();
    this.userform_204888_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      CheckIfKUserAlreadyExistsByUserName: 0,
      InvertBit: [],
      SaveUser: "",
      SaveRecord_1: false,
      SaveRecord_2: false,
      GoBack: "",
      GetKUser: [],
      GetCompanyByUserAccess: [],
      GetRolesByUserAccess: [],
      IsUserActive: [],
      isComp548941Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("userform", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.UserFormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("userform", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("userform", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UserFormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form?.setFieldsValue({
      userform_6098384_value: this.state.GetKUser?.at?.(0)?.userName ?? undefined,
      userform_197494_value: this.state.GetKUser?.at?.(0)?.firstName ?? undefined,
      userform_516795_value: this.state.GetKUser?.at?.(0)?.lastName ?? undefined,
      userform_126259_value: this.state.GetKUser?.at?.(0)?.companyID ?? undefined,
      userform_204888_value: this.state.GetKUser?.at?.(0)?.roleId ?? undefined,
      userform_716235_value: this.state.IsUserActive?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form?.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  UserFormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserForm/UserFormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUser = result?.data.getKUser;
    formVars.userform_6098384_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].userName
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].userName
        : null
    );
    formVars.userform_197494_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].firstName
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].firstName
        : null
    );
    formVars.userform_516795_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].lastName
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].lastName
        : null
    );
    formVars.userform_126259_value =
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].companyID
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].companyID
        : null;
    formVars.userform_126259_options = stateVars.GetCompanyByUserAccess;
    formVars.userform_204888_value =
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].roleId
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].roleId
        : null;
    formVars.userform_204888_options = stateVars.GetRolesByUserAccess;
    stateVars.isComp548941Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetCompanyByUserAccess = result?.data.getCompanyByUserAccess;

    formVars.userform_126259_value =
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].companyID
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].companyID
        : null;
    formVars.userform_126259_options = stateVars.GetCompanyByUserAccess;
    stateVars.GetRolesByUserAccess = result?.data.getRolesByUserAccess;

    formVars.userform_204888_value =
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].roleId
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].roleId
        : null;
    formVars.userform_204888_options = stateVars.GetRolesByUserAccess;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UserFormPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserFormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].id
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].id
            : null
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UserForm/UserFormPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.IsUserActive = result?.data.isUserActive;
      formVars.userform_716235_value = ReactSystemFunctions.toBoolean(
        this,
        stateVars.IsUserActive?.length > 0
          ? stateVars.IsUserActive[0].isActive
          : this.state.IsUserActive?.length > 0
          ? this.state.IsUserActive[0].isActive
          : null
      );
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormPageInit2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormPageInit2_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserFormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.userform_6098384_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].userName
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].userName
        : null
    );

    formVars.userform_197494_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].firstName
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].firstName
        : null
    );

    formVars.userform_516795_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].lastName
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].lastName
        : null
    );

    formVars.userform_126259_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].companyID
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].companyID
        : null
    );

    stateVars.dataSource_126259 = this.state.GetCompanyByUserAccess;
    stateVars.dataSource_126259 = this.state.GetCompanyByUserAccess;
    formVars.userform_204888_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUser?.length > 0
        ? stateVars.GetKUser[0].roleId
        : this.state.GetKUser?.length > 0
        ? this.state.GetKUser[0].roleId
        : null
    );

    stateVars.dataSource_204888 = this.state.GetRolesByUserAccess;
    stateVars.dataSource_204888 = this.state.GetRolesByUserAccess;
    stateVars.isComp548941Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UserFormPageInit3_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  UserFormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        null
      )
    ) {
      formVars.userform_716235_value = ReactSystemFunctions.toBoolean(
        this,
        stateVars.IsUserActive?.length > 0
          ? stateVars.IsUserActive[0].isActive
          : this.state.IsUserActive?.length > 0
          ? this.state.IsUserActive[0].isActive
          : null
      );

      this.props.form?.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UserFormComponent_839541_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_6098384_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_6098384_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_6098384_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].id
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].id
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UserForm/UserFormComponent_839541_onClick", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.CheckIfKUserAlreadyExistsByUserName = result?.data.checkIfKUserAlreadyExistsByUserName;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormComponent_839541_onClick1_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormComponent_839541_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserFormComponent_839541_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_6098384_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_6098384_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_197494_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_197494_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_516795_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_516795_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_204888_value", "value", "GetRolesByUserAccess", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_204888_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BitValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "userform_716235_value", "value", "", "", "")
        ),
        "boolean"
      ),
      userName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_6098384_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_197494_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_516795_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_204888_value", "value", "GetRolesByUserAccess", "id", "name")
        ),
        "string"
      ),
      startingScreenId_1: ReactSystemFunctions.convertToTypeByName("404effc6-04b7-4639-9f90-e371e6bae973", "Guid"),
      phoneNumber_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      dontSendEmail_1: ReactSystemFunctions.convertToTypeByName("", "boolean"),
      twoFactorAuthenticationType_1: ReactSystemFunctions.convertToTypeByName("", "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserForm/UserFormComponent_839541_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.InvertBit = result?.data.invertBit;
    stateVars.SaveUser = result?.data.saveUser;
    this.props.form?.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.UserFormComponent_839541_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserFormComponent_839541_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_126259_value", "value", "GetCompanyByUserAccess", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_126259_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      KUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.SaveUser?.id),
        "Guid"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userform_126259_value", "value", "GetCompanyByUserAccess", "id", "id")
        ),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UserForm/UserFormComponent_839541_onClick2_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord_1 = result?.data.saveRecord_1;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormComponent_839541_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormComponent_839541_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserFormComponent_839541_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "userform_126259_value", "value", "GetCompanyByUserAccess", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "userform_126259_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        "Guid"
      ),
      KUserID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.userId ?? this.props.screenInputs.userid,
        "Guid"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userform_126259_value", "value", "GetCompanyByUserAccess", "id", "id")
        ),
        "Guid"
      ),
      IsDisabled_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          stateVars.InvertBit?.length > 0
            ? stateVars.InvertBit[0].result
            : this.state.InvertBit?.length > 0
            ? this.state.InvertBit[0].result
            : null
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.GetKUser?.length > 0
            ? stateVars.GetKUser[0].userSettingsId
            : this.state.GetKUser?.length > 0
            ? this.state.GetKUser[0].userSettingsId
            : null
        ),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "UserForm/UserFormComponent_839541_onClick3_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord_2 = result?.data.saveRecord_2;
      this.props.form?.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormComponent_839541_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.UserFormComponent_839541_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserFormComponent_839541_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserFormComponent_25335_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserFormComponent_120216_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form?.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
